<template>
  <div id="app" class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="alert alert-primary my-4" role="alert">
          Derived from Ed Zimbardi's Traide Ringer Input Settings Calculator
        </div>
      </div>

      <div class="col-md-3 col-sm-12">
        <div class="card">
          <div class="card-body p-3">
            <form @submit.prevent="renderDrawDownTable">
              <div class="form-group">
                <label for="equity">Equity</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="settings.equity"
                  id="equity"
                  step="1"
                  min="0">
              </div>

              <div class="form-group">
                <label for="pairs">Currency Pairs</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="settings.pairs"
                  id="pairs"
                  step="1"
                  min="0">
              </div>

              <div class="form-group">
                <label for="lotSize">Start Lot Size</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="settings.lotSize"
                  id="lotSize"
                  step="0.01"
                  min="0">
              </div>

              <div class="form-group">
                <label for="levelRange">Level Range</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="settings.levelRange"
                  id="levelRange"
                  step="1"
                  min="0">
              </div>

              <div class="form-group">
                <label for="levelRangeMultiplier">Level Range Multiplier</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="settings.levelRangeMultiplier"
                  id="levelRangeMultiplier"
                  step="0.01"
                  min="0">
              </div>

              <div class="form-group">
                <label for="lotMultiplier">Position Size Multiplier</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="settings.lotMultiplier"
                  id="lotMultiplier"
                  step="0.01"
                  min="0"
                >
              </div>

              <div class="form-group">
                <label for="maxLevel">Max Level</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="settings.maxLevel"
                  id="maxLevel"
                  step="1"
                  min="0">
              </div>

              <button class="btn btn-primary btn-block">
                Calculate
              </button>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-9 col-sm-12">
        <div class="card">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Lot Size</th>
                  <th>Level Range</th>
                  <th>Total Pips</th>
                  <th>Drawdown($)</th>
                  <th>Drawdown(%)</th>
                  <th>Equity</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="row in levelRows"
                  :key="row.level"
                  :class="setClass(row)"
                >
                  <td>{{ row.level }}</td>
                  <td>{{ row.lotSize }}</td>
                  <td>{{ row.levelRange }}</td>
                  <td>{{ row.totalPips }}</td>
                  <td>${{ Number(row.drawdownAmount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }) }}</td>
                  <td>{{ row.drawdownPercentage }}%</td>
                  <td>${{ Number(row.equity).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      price: 1.10503,
      settings: {
        pairs: 1,
        equity: 1000,
        lotSize: 0.02,
        lotMultiplier: 1.4,
        levelRange: 25,
        levelRangeMultiplier: 1.15,
        maxLevel: 7,
        pipValue: 10,
        levels: 12,
      },
      levelRows: [],
    };
  },
  created() {
    this.renderDrawDownTable();
    window.dataLayer = window.dataLayer || [];
    this.gtag('js', new Date());
    this.gtag('config', 'G-X8BBGT1R5F');
  },
  methods: {
    renderDrawDownTable() {
      this.levelRows = [];
      if (this.settings.maxLevel > 0) {
        let lotSize = this.settings.lotSize;
        let levelRange = 0;
        let pips = 0;
        let drawdownAmount = 0;
        let equity = 0;
        let drawdownLotSize = lotSize;

        for (let i = 0; i < this.settings.levels; i++) {
          if ( i == 1) {
            levelRange = this.settings.levelRange;
          }

          if ( i > 1) {
            levelRange = (levelRange * this.settings.levelRangeMultiplier).toFixed(0);
            drawdownLotSize = this.levelRows[i - 1].lotSize;
          }

          pips += Number(levelRange);
          drawdownAmount = Number(pips) * this.settings.pipValue * Number(drawdownLotSize);
          equity = this.settings.equity - drawdownAmount;

          this.levelRows.push({
            level: i,
            lotSize: lotSize,
            levelRange: levelRange,
            totalPips: pips,
            drawdowns: [],
            drawdownAmount: (drawdownAmount * -1).toFixed(2),
            drawdownPercentage: ((drawdownAmount / equity) * 100).toFixed(2),
            equity: equity,
          });

          lotSize = parseFloat(this.settings.lotMultiplier) > 1 && parseFloat(lotSize) == parseFloat(this.settings.lotSize) ?
                    (parseFloat(lotSize) + 0.01) * 100:
                    (parseFloat(lotSize) * parseFloat(this.settings.lotMultiplier)) * 100 ;

          lotSize = (Math.round(lotSize)/100).toFixed(2);
        }

        this.calculateLevelDrawDowns();
        this.calculateLevelDrawdownAmount();
      }
    },
    calculateLevelDrawDowns() {
      for (let i = 0; i < this.settings.levels; i++) {
        this.levelRows.map((row) => {
          let difference = (i >= row.level) ? 0 : Number(row.totalPips) - this.levelRows[i].totalPips;
          let drawdown = Number(this.levelRows[i].lotSize) * Number(this.settings.pipValue) * Number(difference);
          this.levelRows[i].drawdowns.push(Number(drawdown.toFixed(2)));
        });
      }
    },
    calculateLevelDrawdownAmount() {
      for (let i = 0; i < this.settings.levels; i++) {
        let total = 0;
        this.levelRows.map((row) => {
          total += Number(row.drawdowns[i]);
        });
        this.levelRows[i].drawdownAmount = (total * -1 * this.settings.pairs).toFixed(2);
        this.levelRows[i].drawdownPercentage = ((total / this.settings.equity) * 100 * this.settings.pairs).toFixed(2);
        this.levelRows[i].equity = (this.settings.equity - (total * this.settings.pairs)).toFixed(2);
      }
    },
    setClass(row) {
      let className = '';

      if (row.level == this.settings.maxLevel - 1) {
        className = 'table-warning font-weight-bold';
      }

      if ((row.drawdownAmount * -1) > this.settings.equity) {
        className = 'table-danger';
      }

      return className;
    },
    gtag() {
      window.dataLayer.push(arguments);
    }
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
